import './App.css';
import FreeTrial from './screens/free-trial';
import ThanksForFreeTrial from './screens/free-trial/thanks';
import Landing from './screens/landing';
import RequestDemo from './screens/request-demo';
import { Routes, Route } from "react-router-dom";
import ThanksForRequestDemo from './screens/request-demo/thanks';
import TermsAndConditions from './screens/terms-and-conditions';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/home" element={<Landing />} />
        <Route path="/free-trial" element={<FreeTrial />} />
        <Route path="/free-trial-thanks" element={<ThanksForFreeTrial />} />
        <Route path="/request-demo" element={<RequestDemo />} />
        <Route path="/request-demo-thanks" element={<ThanksForRequestDemo />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </div>
  );
}

export default App;
