import React from "react";
import FreeTrial from ".";
import './style.scss';
import CloseIcon from '../../assets/images/close.png';
import { useNavigate } from "react-router-dom";

const ThanksForFreeTrial = () => {
    const navigate = useNavigate();

    return (
        <div className="thanks-container">
            <FreeTrial showForm/>
            <div className="thanks-modal-container">
                <div className="modal-content">
                    <div className="close-container" onClick={() => navigate('/')}>
                        <img src={CloseIcon} alt="close" />
                    </div>
                    <div className="modal-head">
                        Thanks for your interest in starting a free trial
                    </div>
                    <div className="modal-desc">We will setup your account and contact you with the details in the next 2 business days.</div>
                    <div className="modal-note">
                        Please keep checking your emails for the details. If you require any other details in the meantime, please contact us on <span className="email">support@burso.com</span> (all business days, 9AM-6PM PST).
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThanksForFreeTrial