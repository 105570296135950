import React from "react";
import '../../App.css'
import Navbar from "../navbar";
import Home from "../home";
import About from "../about";
import SetsApart from "../setsapart";
import WhosUsing from "../whos-using";
import Footer from "../footer";

const Landing = () => {

    return (
        <div className="App">
            <Navbar name="login"/>
            <Home />
            <About />
            <SetsApart />
            <WhosUsing />
            <Footer />
        </div>
    )
}

export default Landing;