import React from "react";
import './style.scss';
import Navbar from "../navbar";
import urls from "../../constants/urls";
import BackIcon from '../../assets/images/back.png'
import { useNavigate } from "react-router-dom";

const FreeTrial = ({ showForm }) => {
    const navigate = useNavigate();

    return (
        <div className="free-trial-container">
            <Navbar name="request-demo" />

            <div className="header-container">
                <div className="header-content">
                    <div className="back-container" onClick={() => navigate('/')}>
                        <img src={BackIcon} alt="back" />
                    </div>
                    <div className="first-head">Start your 90-day free trial</div>
                    <div className="second-head">Begin your journey towards greatness!</div>
                </div>

                <div className="form-container">
                    <div className="header">Enter your details</div>
                    {!showForm && <iframe src={urls.freeTrial} className="iframe-style" />}

                    {showForm && <div className="form-flex">
                        <div className="form-item">
                            <div className="label">First Name<span className="required">*</span></div>
                            <input className="form-input" placeholder="First Name" />
                        </div>
                        <div className="form-item">
                            <div className="label">Last Name<span className="required">*</span></div>
                            <input className="form-input" placeholder="Last Name" />
                        </div>
                        <div className="form-item">
                            <div className="label">Business Email<span className="required">*</span></div>
                            <input className="form-input" placeholder="Business Email" />
                        </div>
                        <div className="form-item">
                            <div className="label">Phone Number<span className="required">*</span></div>
                            <input className="form-input" placeholder="Phone Number" />
                        </div>
                        <div className="form-item">
                            <div className="label">Job Title<span className="required">*</span></div>
                            <input className="form-input" placeholder="Job Title" />
                        </div>
                    </div>}

                    {showForm && <div className="form-flex">
                        <div className="form-item">
                            <div className="label">Organization Name<span className="required">*</span></div>
                            <input className="form-input" placeholder="Organization Name" />
                        </div>
                        <div className="form-item">
                            <div className="label">Organization Size</div>
                            <input className="form-input" placeholder="Organization Size" />
                        </div>
                        <div className="form-item">
                            <div className="label">Type of CAPS You Use</div>
                            <input className="form-input" placeholder="Type of CAPS You Use" />
                        </div>
                    </div>}

                    {showForm && <div className="form-flex">
                        <div className="form-item">
                            <div className="label">Number of User Licenses Required<span className="required">*</span></div>
                            <input className="form-input" placeholder="Number of User Licenses Required" />
                        </div>
                        <div className="form-item">
                            <div className="label">When Do You Perform Your CAP</div>
                            <input className="form-input" placeholder="When Do You Perform Your CAP" />
                        </div>
                    </div>}

                    <div className="already-loggedin">
                        <div className="inner">
                            Already have an account?
                            <div className="link" onClick={() => window.open(urls.login, '_blank')}>Log in</div>
                            instead
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreeTrial;