import React from "react";
import '../free-trial/style.scss';
import Navbar from "../navbar";
import BackIcon from '../../assets/images/back.png'
import { useNavigate } from "react-router-dom";
import urls from "../../constants/urls";

const RequestDemo = ({showForm}) => {
    const navigate = useNavigate();

    return (
        <div className="free-trial-container">
            <Navbar name="free-trial" />

            <div className="header-container">
                <div className="header-content">
                    <div className="back-container" onClick={() => navigate('/')}>
                        <img src={BackIcon} alt="back" />
                    </div>
                    <div className="first-head">Request a demo</div>
                </div>

                <div className="form-container">
                    <div className="header">Enter your details</div>
                    {!showForm && <iframe src={urls.requestDemo} className="iframe-style"/>}

                    {showForm && <div className="form-flex">
                        <div className="form-item">
                            <div className="label">First Name<span className="required">*</span></div>
                            <input className="form-input" placeholder="First Name" />
                        </div>
                        <div className="form-item">
                            <div className="label">Last Name<span className="required">*</span></div>
                            <input className="form-input" placeholder="Last Name" />
                        </div>
                        <div className="form-item">
                            <div className="label">Business Email<span className="required">*</span></div>
                            <input className="form-input" placeholder="Business Email" />
                        </div>
                        <div className="form-item">
                            <div className="label">Phone Number<span className="required">*</span></div>
                            <input className="form-input" placeholder="Phone Number" />
                        </div>
                        <div className="form-item">
                            <div className="label">Organization Name<span className="required">*</span></div>
                            <input className="form-input" placeholder="Organization Name" />
                        </div>
                        <div className="form-item">
                            <div className="label">Job Title<span className="required">*</span></div>
                            <input className="form-input" placeholder="Job Title" />
                        </div>
                    </div>}

                    {showForm && <div className="form-flex">
                        <div className="form-item textarea-item">
                            <div className="label">Comments</div>
                            <textarea className="form-textarea" placeholder="Number of User Licenses Required" />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default RequestDemo;