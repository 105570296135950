import React from "react";
import './style.scss';
import AllocationsImage from '../../assets/images/setsapart/allocation.png';
import AccountingImage from '../../assets/images/setsapart/accounting.png';
import EquitableImage from '../../assets/images/setsapart/equitible.png';
import SmartImage from '../../assets/images/setsapart/smart.png';

const SetsApart = () => {

    return (
        <div className="sets-apart-container">
            <div className="heading">What sets us apart</div>

            <div className="tiles">
                <div className="tiles-item">
                    <div className="tiles-img">
                        <img src={AllocationsImage} alt="allocation" />
                    </div>
                    <div className="tiles-head">Allocation methods</div>
                    <div className="tiles-desc">
                        Utilizes an unlimited number of cost pools and allocation methods to precisely allocate costs.
                    </div>
                </div>
                <div className="tiles-item">
                    <div className="tiles-img">
                        <img src={AccountingImage} alt="allocation" />
                    </div>
                    <div className="tiles-head">Accounting system</div>
                    <div className="tiles-desc">
                        Leverages modern accounting system capabilities, allowing for the import of all data.
                    </div>
                </div>
                <div className="tiles-item">
                    <div className="tiles-img">
                        <img src={EquitableImage} alt="allocation" />
                    </div>
                    <div className="tiles-head">Equitable sharing</div>
                    <div className="tiles-desc">
                        Helps meet internal and external requirements, allowing costs to be shared equitably and defensibly.                     </div>
                </div>
                <div className="tiles-item">
                    <div className="tiles-img">
                        <img src={SmartImage} alt="allocation" />
                    </div>
                    <div className="tiles-head">Smart allocation</div>
                    <div className="tiles-desc">
                        Offers smarter cost allocation solutions to help public agencies do more for their communities.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetsApart