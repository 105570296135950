import React from "react";
import './style.scss';

const WhosUsing = () => {

    return (
        <div className="whos-using-container">
            <div className="content-container">
                <div className="heading">Who's using</div>
                <div className="heading">our product</div>
                <div className="desc">
                    Burso is a SaaS product that assists all types of public agencies in preparing Cost Allocation Plans (CAPs) of varying types
                </div>

                <div className="tiles">
                    <div className="tiles-item">City Manager​s</div>
                    <div className="tiles-item">CFOs</div>
                    <div className="tiles-item">Budget/Finance/Policy Directors</div>
                    <div className="tiles-item">Accounting Director/Managers</div>
                    <div className="tiles-item">Budget Officers</div>
                    <div className="tiles-item">Procurement Officers</div>
                    <div className="tiles-item">Comptroller, Controllers</div>
                    <div className="tiles-item">Executive Directors</div>

                </div>
            </div>
        </div>
    )
}

export default WhosUsing