import React from "react";
import '../free-trial/style.scss';
import CloseIcon from '../../assets/images/close.png';
import { useNavigate } from "react-router-dom";
import RequestDemo from ".";

const ThanksForRequestDemo = () => {
    const navigate = useNavigate();

    return (
        <div className="thanks-container">
            <RequestDemo showForm/>
            <div className="thanks-modal-container">
                <div className="modal-content">
                    <div className="close-container" onClick={() => navigate('/')}>
                        <img src={CloseIcon} alt="close" />
                    </div>
                    <div className="modal-head">
                        Thanks for your interest in taking a demo for Burso
                    </div>
                    <div className="modal-desc">Our executive will contact you within next 2 business days to setup a detailed demo session for you.</div>
                </div>
            </div>
        </div>
    )
}

export default ThanksForRequestDemo