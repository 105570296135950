import React from "react";
import './style.scss';
import LogoImage from '../../assets/images/logo.png';
import LoginImage from '../../assets/images/login.png';
import urls from "../../constants/urls";
import { useNavigate } from "react-router-dom";

const Navbar = ({ name }) => {
    const navigate = useNavigate();

    const handleButton = () => {
        if (name === 'request-demo') navigate('/request-demo');
        else if (name === 'free-trial') navigate('/free-trial')
        else window.open(urls.login, '_blank');
    }

    return (
        <div className="navbar-container">
            <div className="logo-container" onClick={() => navigate('/')}>
                <img src={LogoImage} alt="logo" />
            </div>

            <div className="login-button" onClick={() => handleButton()}>
                {name === 'request-demo' ? 'REQUEST A DEMO' : name === 'free-trial' ? '90-DAY FREE TRIAL' : 'LOGIN'}
                <img src={LoginImage} alt="login" />
            </div>
        </div>
    )
}

export default Navbar