import React, { useEffect, useState } from "react";
import './style.scss';
import ProcessImage from '../../assets/images/process.png';
import GraphImage from '../../assets/images/graph.png';
import ProcessVideo from '../../assets/videos/process.mp4';
import AdminCostsImage from '../../assets/images/about/admin-costs.png';
import PlansImage from '../../assets/images/about/plans.png';
import ServicesImage from '../../assets/images/about/service.png';
import DepartmentalImage from '../../assets/images/about/departmental.png';
import TeamsImage from '../../assets/images/about/teams.png';
import ActivityImage from '../../assets/images/about/activity.png';

const About = () => {
    const [active, setActive] = useState(1);
    const [timeLeft, setTimeLeft] = useState(4);

    useEffect(() => {
        if (!timeLeft) {
            setActive(active < 3 ? active + 1 : 1);
            setTimeLeft(4);
        };

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const TilesComponent = (styleName) => {
        return (
            <div className={`about-tiles ${styleName}`}>
                <div className="tile-item">
                    <img src={AdminCostsImage} className="tile-image" alt="admin-cost" />
                    <div className="tile-desc">
                        Allocate indirect and admin costs to benefitting programs
                    </div>
                </div>
                <div className="tile-item">
                    <img src={PlansImage} className="tile-image" alt="admin-cost" />
                    <div className="tile-desc">
                        Complete Statewide Cost Allocation Plans (SWCAPs)
                    </div>
                </div>
                <div className="tile-item">
                    <img src={ServicesImage} className="tile-image" alt="admin-cost" />
                    <div className="tile-desc">
                        Complete Agency-wide Local Government Central Service CAPs
                    </div>
                </div>
                <div className="tile-item">
                    <img src={DepartmentalImage} className="tile-image" alt="admin-cost" />
                    <div className="tile-desc">
                        Complete Departmental CAPs
                    </div>
                </div>
                <div className="tile-item">
                    <img src={TeamsImage} className="tile-image" alt="admin-cost" />
                    <div className="tile-desc">
                        Collaborate on CAPs simultaneously across teams of any size
                    </div>
                </div>
                <div className="tile-item">
                    <img src={ActivityImage} className="tile-image" alt="admin-cost" />
                    <div className="tile-desc">
                        Analyze year-over-year comparisons of cost plan results
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div
                className="about-container"
                style={{ minHeight: `calc(40vh + 100px + 200px + ${window.innerWidth > 780 ? '500px' : '100px'} + ${Math.round((50.96 / 100) * ((90 / 100) * window.innerWidth))}px)`}}
            >
                <div className="content-container">
                    <div className="content-head">Accurate. Efficient. Transparent.</div>
                    <div className="graph-image-container">
                        <img src={GraphImage} alt="graph" className="graph-image" />
                        {active === 1 && <div
                            style={{ left: '23.5%' }}
                            className="animation-border"
                        />}
                        {active === 2 && <div
                            style={{ left: '42%' }}
                            className="animation-border"
                        />}
                        {active === 3 && <div
                            style={{ left: '60.5%' }}
                            className="animation-border"
                        />}
                    </div>
                    <div className="content-desc">Burso is a proven cost allocation solution developed and supported by MGT government
                        accounting experts to provide accurate, efficient, transparent and defensible allocation
                        of public funds.
                    </div>
                </div>

                {TilesComponent('web-part')}

            </div>
            {TilesComponent('mobile-part')}
        </>
    )
}

export default About