import React from "react";
import './style.scss';
import HomeVideo from '../../assets/videos/home.mp4';
import EllipseImage from '../../assets/images/ellipse.png';
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    const handleFreeTrial = () => {
        navigate('/free-trial')
    }

    const handleRequestDemo = () => {
        navigate('/request-demo')
    }

    return (
        <div className="home-container">
            <div className="content-container">
                <div className="first-line">STREAMLINE YOUR</div>
                <div className="second-line">COST ALLOCATION</div>
                <div className="desc">
                    Make cost allocation easier and more accurate. Eliminate the guesswork of calculations to create a collaborative, transparent accounting environment.
                </div>
                <div className="home-buttons">
                    <div className="free-trial" onClick={() => handleFreeTrial()}>Start 90-day free trial</div>
                    <div className="request-demo" onClick={() => handleRequestDemo()}>Request Demo</div>
                </div>
                <div className="add-message">*No credit card required</div>
            </div>

            <div className="right-image">
                <img src={EllipseImage} alt="ellipse" />
            </div>

            <div className="video-container">
                <video autoPlay loop muted>
                    <source src={HomeVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    )
}

export default Home