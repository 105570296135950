import React from "react";
import './style.scss';
import StartedImage from '../../assets/images/get-started.png';
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    const handleFreeTrial = () => {
        navigate('/free-trial')
    }

    return (
        <div className="footer-container">
            <div className="get-started">
                <div className="message" onClick={() => handleFreeTrial()}>
                    <div>
                        <div className="top-message">Get started</div>
                        <div className="small-message">Fill the contact form & </div>
                        <div className="small-message">Start your 90-day Free trial! </div>
                    </div>
                    <img src={StartedImage} className="started-image" />
                </div>
            </div>

            <div className="footer">
                <div className="left-container">
                    <div>
                        <div className="details">+1 (813) 327-4717</div>
                        <div className="details-underlined">4320 West Kennedy Boulevard</div>
                        <div className="details-underlined">Tampa, Florida 33609</div>
                        <div className="details">United States of America</div>
                    </div>
                </div>
                <div className="copyright">
                    © 2023 MGT. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer