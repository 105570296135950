import React from "react";
import '../free-trial/style.scss';
import CloseIcon from '../../assets/images/close.png';
import { useNavigate } from "react-router-dom";
import FreeTrial from "../free-trial";

const TermsAndConditions = () => {
    const navigate = useNavigate();

    return (
        <div className="thanks-container">
            <FreeTrial showForm />
            <div className="thanks-modal-container">
                <div className="terms modal-content">
                    <div className="close-container" onClick={() => navigate('/free-trial')}>
                        <img src={CloseIcon} alt="close" />
                    </div>
                    <div className="modal-head">
                        Terms & Conditions
                    </div>
                    <div className="terms-desc">
                        <span className="space-more">11111</span> These Subscription Agreement: Terms and Conditions (these <strong>"T&Cs"</strong>) are between MGT OF AMERICA CONSULTING, LLC, a Florida limited liability company with offices located at 4320 West Kennedy Boulevard, Tampa, FL 33609 (<strong>"MGT"</strong>) and the subscriber identified on the submission form (<strong>"Subscriber"</strong>). MGT and Subscriber are each referred to herein as a <strong>"Party"</strong>, and collectively, the <strong>"Parties"</strong>. The term "Subscriber" also includes its Authorized Users (as context permits).
                    </div>
                    <div className="terms-desc">
                        <span className="space-more">11111</span> These T&Cs and any future Amendments are collectively referred to herein as the <strong>"Agreement"</strong>
                    </div>
                    <div className="terms-head">Recitals</div>
                    <div className="terms-desc">
                        <span className="space-more">11111</span> <strong>WHEREAS</strong>, MGT has developed a certain proprietary database tool and platform, Burso, (the "MGT Platform"), which is a cloud-based SaaS solution that performs various types of Cost Allocation Plans (CAPs) utilizing both imported and manually updated expenditure and statistical information. The system performs double step-down calculations, and provides a suite of reports suitable for both Federal and Full Cost plan purposes;
                    </div>
                    <div className="terms-desc">
                        <span className="space-more">11111</span><strong>WHEREAS</strong>, Subscriber wishes to obtain a limited subscription license to use the MGT Solution (as defined below) for the purposes set forth in this Agreement; and
                    </div>
                    <div className="terms-desc">
                        <span className="space-more">11111</span><strong>WHEREAS</strong>, MGT is willing to provide such limited subscription license to Subscriber at no cost for a limited duraton, subject to the terms and conditions of this Agreement;
                    </div>
                    <div className="terms-desc">
                        <span className="space-more">11111</span><strong>NOW, THEREFORE</strong>, for good and sufficient consideration, the receipt and sufficiency of which is hereby acknowledged, the Parties agree as follows:
                    </div>

                    <div className="terms-head">Agreement</div>
                    <div className="terms-desc">
                        <strong>1.<span className="space"></span>Definitions{'. '}</strong> For purposes of this Agreement, the following capitalized terms have the meanings set forth below. Other capitalized terms are defined in context.
                    </div>

                    <div className="terms-desc start-margin">
                        <strong>1.1</strong><span className="space"></span>
                        <strong>Access Credentials.</strong> The term <strong>"Access Credentials"</strong> has the meaning set out in Section 2.1.2 (Access Credentials).
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.2</strong><span className="space"></span>
                        <strong>Authorized User.</strong> The term <strong>"Authorized User"</strong> means any end user to whom Subscriber has provided the Access Credentials.  An Authorized User may be removed by MGT pursuant to standard SOC provisions.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.3</strong><span className="space"></span>
                        <strong>Documentation.</strong> The term <strong>"Documentation"</strong> means written, printed, or electronically provided manuals, product descriptions, and other documentation, which MGT in its discretion may make available to Subscriber (if any). The term "Documentation" does not include marketing materials.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.4</strong><span className="space"></span>
                        <strong>MGT Platform.</strong> The term <strong>"MGT Platform"</strong> means: (i) the specific online platform to which MGT provides Subscriber with access, as further described in the related Documentation; (ii) the MGT System; and (iii) any MGT-provided modifications, enhancements, or updates to the foregoing.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.5</strong><span className="space"></span>
                        <strong>MGT Solution.</strong> The term <strong>"MGT Solution"</strong> means: (i) the MGT Platform; (ii) the Services; (iii) Documentation; and (iv) any MGT-provided modifications, enhancements, or updates to the foregoing.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.6</strong><span className="space"></span>
                        <strong>MGT System.</strong> The term <strong>"MGT System"</strong> means the software, business rules, methods, data, know-how, and technology developed or acquired by MGT to enable the services it provides to its customers, including all computers, software, hardware, databases, electronic systems (including database management systems), and networks, whether operated directly by MGT or through the use of third party services.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.7</strong><span className="space"></span>
                        <strong>Intellectual Property Rights.</strong> The term <strong>"Intellectual Property Rights"</strong> means any rights (whether owned or licensed) existing now or in the future under patent law, copyright law, trademark law, data and database protection law, trade secret law, and any and all similar proprietary rights. The term "Intellectual Property Rights" means those rights as they exist as of the Effective Date as well as all such rights subsequently acquired.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.8</strong><span className="space"></span>
                        <strong>Other Agreements.</strong> The term <strong>"Other Agreements"</strong> means any acceptable use policy, terms of conduct, terms of use, privacy policy, or other agreements that the Parties may enter into from time to time or that MGT may make available to Subscriber and/or Authorized Users from time to time.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.9</strong><span className="space"></span>
                        <strong>Services.</strong> The term <strong>"Services"</strong> means those services that MGT provides to Subscriber pursuant to the Agreement, including, as applicable, Onboarding Services and Support Services.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>1.10</strong><span className="space"></span>
                        <strong>Subscriber Data.</strong> The term <strong>"Subscriber Data"</strong> means all data, information, content, work, and other material that Subscriber or its Authorized Users upload to or otherwise make available via the MGT Solution.
                    </div>


                    <div className="terms-desc">
                        <strong>2.<span className="space"></span>Grant of Rights to Subscriber.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>2.1. Subscription License to MGT Platform.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <span className="space"></span><strong>2.1.1</strong><span className="space"></span>
                        <strong> Non-Exclusive Rights in the MGT Platform.</strong> MGT, under its Intellectual Property Rights, hereby grants to Subscriber during the Term, a limited, non-exclusive, and non-transferrable subscription license to permit Authorized Users to access and use the MGT Platform for the Subscriber's internal business purposes; provided that such access and use shall be (i) limited to cloud-based access to an instance of the MGT Platform that resides in object code form on servers controlled by MGT and that MGT makes available to Subscriber; (ii) via a browser approved by MGT; and (iii) the MGT Platform shall in all respects be the current version generally made available by MGT to all end users.
                    </div>
                    <div className="terms-desc start-margin">
                        <span className="space"></span><strong>2.1.2</strong><span className="space"></span>
                        <strong>Post-Term Access.</strong> The Parties recognize that Subscriber has no right to access to the MGT Platform after the end of the Term.
                    </div>
                    <div className="terms-desc start-margin">
                        <span className="space"></span><strong>2.1.3</strong><span className="space"></span>
                        <strong>Access Credentials.</strong> The term <strong>"Access Credential"</strong> means any access code, invitation code, user name, password, key, or other credential used to verify and authorize the use of the MGT Platform. MGT relies on Access Credentials to verify Authorized Users in order to provide them with access to the MGT Platform. Accordingly, Subscriber agrees, and shall cause each Authorized User to agree, that: (i) Access Credentials shall not be shared with any party not contemplated by this Agreement; (ii) immediately notify MGT and the Subscriber if the security, confidentiality, or integrity of the Access Credentials have been compromised; and (iii) Subscriber and each Authorized User are: (a) solely responsible for maintaining the confidentiality of the Access Credentials, and (b) as between MGT and Subscriber, Subscriber is solely liable for any and all use of the MGT Platform that occurs as a result of access to it via the Access Credentials by Authorized Users. MGT shall provide Access Credentials to Subscriber, and Subscriber shall be responsible for distributing the Access Credentials to its Authorized Users.
                    </div>

                    <div className="terms-desc start-margin">
                        <strong>2.2. Non-Exclusive Rights in Documentation.</strong>
                        MGT, under its Intellectual Property Rights, hereby grants to Subscriber during the Term, a limited, non-exclusive, and non-transferrable license to access and use (but not modify), and to permit its Authorized Users to access and use (but not modify), the Documentation solely in connection with its use of the MGT Solution.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>2.3</strong><span className="space"></span>
                        <strong>Third Party Materials.</strong> The MGT Solution may incorporate, embed, or be bundled with software, data, databases, or components that are owned by third parties, including software, data, databases, or components that are subject to terms and conditions of third party licenses (collectively, the "Third Party Materials"). Use of Third Party Materials, if any, is governed by the terms and conditions contained in the applicable third party's license agreement or other applicable agreement, a copy of which shall be provided upon request.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>2.4</strong><span className="space"></span>
                        <strong>Restrictions.</strong> Subscriber agrees that it shall not: (i) sell, rent, lease, sublicense, transfer, modify, create derivative works of, or redistribute the MGT Solution; (ii) use or otherwise exploit the MGT Solution except solely as expressly permitted pursuant to Section 2.1.1 (Non-Exclusive Rights in the MGT Platform); (iii) alter or remove any copyright, patent, trademark, or other protective notices included in the MGT Solution; (iv) reverse engineer, decompile, or disassemble the MGT Solution or otherwise attempt to derive any related source code; or (v) allow any party other than Authorized Users to access or otherwise use the MGT Solution.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>2.5</strong><span className="space"></span>
                        <strong>No Implied Rights.</strong> Nothing in this Agreement shall be construed as granting Subscriber any rights other than those expressly provided herein. Any rights granted to Subscriber under this Agreement must be expressly provided herein, and there shall be no implied rights pursuant to this Agreement based on any course of conduct or other construction or interpretation thereof. All rights and licenses not expressly granted to Subscriber herein are expressly reserved by MGT.
                    </div>

                    <div className="terms-desc">
                        <strong>3.<span className="space"></span> Subscriber Grant of Rights to MGT.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>3.1</strong><span className="space"></span>
                        <strong>License to Subscriber Data.</strong> Subscriber, under its Intellectual Property Rights, hereby grants to MGT, during the Term, a non-exclusive, non-sublicensable, non-transferable, limited license to use, display, publish, and reproduce Subscriber Data for purposes of performing its obligations under this Agreement, and for maintenance and observation of the MGT Solution's usage.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>3.2</strong><span className="space"></span>
                        <strong>Contributions.</strong> Subscriber, under its Intellectual Property Rights, hereby grant to MGT a non-exclusive, perpetual, worldwide, irrevocable, royalty-free, fully paid-up, sublicensable, and transferrable license to copy, use, modify, and otherwise utilize Subscriber's suggestions, recommendations, and ideas relating to the MGT Solution or MGT's business (collectively, the "Contributions") for any and all purposes and for as long as MGT sees fit. By way of clarification, and not limitation, Subscriber agrees that: (i) Subscriber has no expectation of review, compensation, or other consideration for MGT's use and exploitation of Contributions; and (ii) MGT is free to use and exploit the Contributions in MGT's sole discretion and without compensation or other obligation to Subscriber.
                    </div>

                    <div className="terms-desc">
                        <strong>4.<span className="space"></span> Ownership.</strong>
                        Other than those rights expressly granted to Subscriber under this Agreement, MGT, its subcontractors, its service providers, and its licensors (as the case may be), retain all right, title, and interest in and to: (i) the MGT Solution and its components, including all Intellectual Property Rights embodied therein; (ii) MGT Confidential Information; and (iii) any modifications, enhancements, updates, and other improvements thereto. Subscriber shall not acquire any rights in the MGT Solution or other MGT Confidential Information by implication, course of conduct, or other means, apart from the express grant of rights provided in this Agreement or any subsequent agreement between the Parties. The MGT Solution is licensed or made available under a subscription license to Subscriber, and not sold
                    </div>


                    <div className="terms-desc">
                        <strong>5.<span className="space"></span> Fees and Payment.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>5.1</strong><span className="space"></span>
                        <strong>Fees.</strong> Subscriber shall be waived for the Term of this Agreement.
                    </div>


                    <div className="terms-desc">
                        <strong>6.<span className="space"></span> MGT Services. </strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>6.1</strong><span className="space"></span>
                        <strong>Support Services; Availability.</strong> During the Term, MGT shall use commercially reasonable efforts to make the material features of the MGT Platform available to Subscriber during MGT's business hours without significant interruption or substantial deviations from the applicable Documentation.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>6.2</strong><span className="space"></span>
                        <strong>Additional Services.</strong> Other than the Onboarding Services and the Support Services, MGT shall not provide any support or services.
                    </div>


                    <div className="terms-desc">
                        <strong>7.<span className="space"></span> Representations and Warranties. </strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>7.1</strong><span className="space"></span>
                        <strong>Mutual Representations and Warranties.</strong> Each Party represents and warrants that: (i) it is duly organized, validly existing, and in good standing, and is qualified and/or licensed to do business in all jurisdictions to the extent necessary to carry out its obligations under this Agreement; (ii) its execution, delivery, and performance of this Agreement will not violate or constitute a default under any agreement by which such Party is bound, or under such Party's organizational documents; (iii) it has the full right, power, and authority to enter into and be bound by the terms and conditions of this Agreement and to perform its obligations under this Agreement; and (iv) this Agreement has been duly executed and delivered by such Party and constitutes a valid and binding agreement, enforceable in accordance with its terms and conditions.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>7.2</strong><span className="space"></span>
                        <strong>Subscriber's Representations and Warranties.</strong> Subscriber represents and warrants to MGT that: (i) the Subscriber Data and Contributions do not and shall not infringe the Intellectual Property Rights or other rights of a third party (including rights under privacy laws); (ii) Subscriber and its Authorized Users shall not upload any virus or malicious code to the MGT Solution or otherwise use the MGT Solution to develop a virus or malicious code; (iii) Subscriber and its Authorized Users shall comply with all applicable federal, state, and local statutes, laws, ordinances, rules and regulations, and the terms of the Other Agreements.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>7.3</strong><span className="space"></span>
                        <strong>Warranty Disclaimer.</strong> EXCEPT AS EXPRESSLY SET FORTH ABOVE (REPRESENTATIONS AND WARRANTIES), MGT DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE MGT SOLUTION AND OTHER MGT CONFIDENTIAL INFORMATION, WHETHER EXPRESS OR IMPLIED. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION 7 (REPRESENTATIONS AND WARRANTIES), THE MGT SOLUTION, AND ALL MGT PERFORMANCE OBLIGATIONS AND ALL PERFORMANCE OBLIGATIONS OF MGT'S SUBCONTRACTORS, SERVICE PROVIDERS, AND LICENSORS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. MGT DOES NOT REPRESENT OR WARRANT THAT: (i) THE MGT SOLUTION, OTHER MGT CONFIDENTIAL INFORMATION, OR OTHER PERFORMANCE OBLIGATIONS WILL MEET SUBSCRIBER'S REQUIREMENTS OR RESULT IN ANY DESIRED OUTCOME, OR (ii) THE MGT SOLUTION'S OPERATION OR DELIVERY WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE FULLEST EXTENT PERMITTED BY LAW, MGT HEREBY DISCLAIMS (FOR ITSELF, ITS SUBCONTRACTORS, SERVICE PROVIDERS, AND LICENSORS) ALL OTHER REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, ORAL OR WRITTEN, WITH RESPECT TO THE MGT SOLUTION AND OTHER PERFORMANCE OBLIGATIONS UNDER THIS AGREEMENT, INCLUDING WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, QUIET ENJOYMENT, ACCURACY, INTEGRATION, MERCHANTABILITY, OR FITNESS FOR ANY PARTICULAR PURPOSE, AND ALL WARRANTIES ARISING FROM ANY COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE.  MGT MAKES NO WARRANTY, AND EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, REGARDING THE USE OR ACCURACY OF THE MGT PLATFORM AND/OR THE MGT SYSTEM FOR AUDIT OR REPORTING PURPOSES; SUBSCRIBER EXPRESSLY ACKNOWLEDGES AND AGREES THAT IT BEARS ALL RESPONSIBILITY AND LIABILITY FOR THE PREPARATION AND CONTENT OF ANY AUDITS OR REPORTS.
                    </div>



                    <div className="terms-desc">
                        <strong>8.<span className="space"></span> Limitations on Remedies. </strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>8.1</strong><span className="space"></span>
                        <strong>Limitation of Liability.</strong> IN NO EVENT SHALL MGT HAVE ANY LIABILITY TO SUBSCRIBER FOR ANY CLAIMS OR DAMAGES OF ANY TYPE ARISING OUT OF THIS AGREEMENT.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>8.2</strong><span className="space"></span>
                        <strong>Waiver of Damages in Data Breach.</strong> IN NO EVENT SHALL MGT BE LIABLE TO SUBSCRIBER FOR ANY DAMAGES OF ANY TYPE RESULTING FROM A DATA BREACH, INCLUDING, WITHOUT LIMITATION, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR NATURE WHATSOEVER.
                    </div>

                    <div className="terms-desc">
                        <strong>9.<span className="space"></span> Indemnification.</strong>
                        Indemnification. Subscriber shall defend, indemnify, and hold MGT and its affiliates and their respective officers, directors, employees, and agents harmless from and against any third party claim, action, suit, or proceeding resulting from: (i) any injury to persons caused by Subscriber's failure to exercise reasonable care or Subscriber's willful misconduct; (ii) any breach of Subscriber's obligations under this Agreement; (iii) Subscriber's or its Authorized Users' use of the MGT Solution; (iv) MGT's use of the Subscriber Data and Contributions in compliance with this Agreement; and (v) the actions and omissions of Authorized Users. Subscriber shall indemnify MGT for losses, damages, liabilities, and reasonable expenses and costs incurred by MGT in such claim, action, suit or proceeding.
                    </div>

                    <div className="terms-desc">
                        <strong>10.<span className="space"></span> Confidentiality.</strong>
                        Confidential Information under this Agreement shall consist of all non-public information disclosed by one Party (the <strong>"Disclosing Party"</strong>) to the other Party (the <strong>"Receiving Party"</strong>), whether oral or in writing (including electronic transmission): (i) that is designated as "Confidential" or "Proprietary" or similar marking by the Disclosing Party at the time of disclosure or within a reasonable period thereafter; (ii) that concerns the customers, finances, methods, research, processes, or procedures of the Disclosing Party or the Disclosing Party's customers, including governmental agencies; or (iii) that by the nature of the circumstances surrounding disclosure, or the information itself, should in good faith, or by law or regulation, be treated as confidential (collectively, the <strong>"Confidential Information"</strong>).
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>10.1</strong><span className="space"></span>
                        <strong>Non-Disclosure; Standard.</strong> The Receiving Party shall retain the Disclosing Party's Confidential Information in strict confidence and shall not use such Confidential Information except for purposes permitted under this Agreement. The Disclosing Party shall be entitled to disclose Confidential Information on a need-to-know basis to its employees, agents, and contractors, who are authorized to access such information, provided that the same are bound by non-disclosure and confidentiality obligations no less protective than those set out in this Agreement. The Receiving Party shall use at least the same degree of care in safeguarding the Disclosing Party's Confidential Information as it uses in safeguarding its own confidential information, but shall not use less than reasonable care and diligence. Except to the extent that a Party retains ongoing rights, the Receiving Party shall return or destroy all Confidential Information of the Disclosing Party (including, but not limited to, all copies of the same) in its possession or control immediately upon the Disclosing Party's request.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>10.2</strong><span className="space"></span>
                        <strong>Exceptions.</strong> The Receiving Party's obligations with respect to the Disclosing Party's Confidential Information shall not apply to Confidential Information that the Receiving Party can demonstrate: (i) is or becomes a matter of public knowledge through no fault of the Receiving Party; (ii) was or becomes available to the Receiving Party on a non-confidential basis from a third party, provided that such third party is not bound by an obligation of confidentiality to the Disclosing Party with respect to such Confidential Information; or (iii) was independently developed by the Receiving Party without reference to the Confidential Information. The Disclosing Party's obligations under Section 10.1 (Non-Disclosure; Standard) shall not apply to information that is required to be disclosed by law, provided that the Disclosing Party is promptly notified by the Receiving Party in order to provide the Disclosing Party an opportunity to seek a protective order or other relief.
                    </div>


                    <div className="terms-desc">
                        <strong>11.<span className="space"></span> Term and Termination.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>11.1</strong><span className="space"></span>
                        <strong>Term.</strong> The Agreement shall commence as of the date subscription is activated and shall continue for a period of ninety (90) days (the <strong>"Term"</strong>). Upon expiration of the Term, this Agreement, Subscriber’s access and rights shall terminate.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>11.2. Termination.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <span className="space"></span><strong>11.2.1</strong><span className="space"></span>
                        <strong>Termination for Convenience.</strong> MGT shall be entitled to terminate this Agreement for any reason or no reason by providing Subscriber with ten (10) day’s written notice.
                    </div>

                    <div className="terms-desc start-margin">
                        <strong>11.3. Effect of Termination or Expiration. </strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <span className="space"></span><strong>11.3.1</strong><span className="space"></span>
                        <strong>Cessation of Use of MGT Solution;</strong> Certain Licenses. Upon termination or expiration of this Agreement, Subscriber's (including its Authorized Users') rights to access and use the MGT Solution shall immediate end and MGT shall have no further obligation to provide the MGT Solution or any services to Subscriber.
                    </div>
                    <div className="terms-desc start-margin">
                        <span className="space"></span><strong>11.3.2</strong><span className="space"></span>
                        <strong>Destruction of Confidential Information.</strong> Unless otherwise expressly permitted under this Agreement, promptly after termination or expiration of this Agreement, each Party shall (i) either (a) return the other Party's tangible Confidential Information, or (b) permanently destroy or irretrievably delete such Confidential Information from storage media; provided, that the foregoing shall not require any deletion where doing so would require an extraordinary effort and such information is (1) archived in the ordinary course, or (2) otherwise not readily accessible; and (ii) destroy all information, records, and materials developed from the other Party's Confidential Information (collectively, the <strong>"Derived Information"</strong>). Upon request, a Party shall certify in writing to the destruction of such Confidential Information and Derived Information.
                    </div>

                    <div className="terms-desc">
                        <strong>12.<span className="space"></span> General.</strong>
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.1</strong><span className="space"></span>
                        <strong>Governing Law; Exclusive Jurisdiction.</strong> This Agreement shall be governed and construed in all respects in accordance with the laws of the State of Florida without regard to any conflict of laws principles. The exclusive venue and jurisdiction for any action or proceeding arising out of this Agreement shall be the courts located in the judicial district that includes Tampa, Florida. The Parties accept the personal jurisdiction of such courts.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.2</strong><span className="space"></span>
                        <strong>Assignment.</strong> This Agreement is not assignable by Subscriber without MGT's prior written consent. MGT reserves the right to assign the rights and obligations under this Agreement for any reason and in MGT's sole discretion. The Parties' rights, duties, and obligations shall bind and inure to the benefit of their respective successors and permitted assigns.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.3</strong><span className="space"></span>
                        <strong>United States Government License Rights;</strong> Restricted Rights. This Section 12.3 (United States Government License Rights; Restricted Rights) applies if Subscriber is a United States government entity or if Subscriber is licensing the MGT Solution on behalf of, or for the benefit of, a United States government entity. The MGT Solution (and its components) are commercial computer software developed at private expense and are provided with RESTRICTED RIGHTS to the United States government. Use, duplication, or disclosure of the MGT Solution by the United States government is subject to the license terms of this Agreement, and, to the extent applicable, FAR 12.212, DFAR 227.7202-1(a), DFAR 227.7202-3(a) and DFAR 227.7202-4 and, to the extent required under United States federal law, the minimum restricted rights as set out in FAR 52.227-19 (DEC 2007). If FAR 52.227-19 is applicable, this provision serves as notice under clause (c) thereof and no other notice is required to be affixed to the MGT Solution. The United States government's rights in the MGT Solution shall be only those set forth in this Agreement.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.4</strong><span className="space"></span>
                        <strong>Force Majeure.</strong> Neither Party shall be liable hereunder by reason of any failure or delay in the performance of its obligations due to any other cause which is beyond its reasonable control, including, by way of example, strikes, shortages, riots, insurrection, fires, flood, storm, explosions, acts of God, terrorism, war, governmental action, earthquakes, pandemics and other public health emergencies.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.5</strong><span className="space"></span>
                        <strong>Independent Contractors.</strong> The Parties are independent contractors, and no agency, partnership, joint venture, or employer-employee relationship is intended or created by this Agreement. Neither Party shall have the power to obligate or bind the other Party.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.6</strong><span className="space"></span>
                        <strong>Severability; Waiver; Headings.</strong> Any provision of this Agreement determined to be unenforceable or invalid by applicable law or court decision shall not render this Agreement unenforceable or invalid as a whole and, in such event, such provision shall be changed and interpreted so as to best accomplish its objectives within the limits of applicable law or court decision. A Party's failure to require the other Party's performance of any obligation herein shall not affect the full right to require such performance at any time thereafter. A Party's waiver of the other Party's breach of any obligation under this Agreement shall not be taken or held to be a waiver of the obligation itself or of any past or subsequent breaches of the same obligation. Headings used in this Agreement are for reference purposes only and in no way define, limit, construe, or describe the scope or extent of such section or in any way affect this Agreement.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.7</strong><span className="space"></span>
                        <strong>Entire Agreement.</strong> This Agreement and the Other Agreements (which are incorporated into this Agreement for all purposes) set forth the entire understanding and agreement of the Parties and supersedes any and all oral or written agreements or understandings between the Parties as to the subject matter of this Agreement. In the event of a conflict or inconsistency between this Agreement and the Other Agreements, this Agreement shall control and such conflict or inconsistency shall be resolved in favor of this Agreement. Neither Party is relying on any warranties, representations, assurances, or inducements not expressly set forth herein. This Agreement may be changed only by a writing signed by both Parties.
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.8</strong><span className="space"></span>
                        <strong>Survival.</strong> In addition to any other right or obligation that by its nature is intended to survive any termination or expiration, the following Sections shall survive any termination or expiration of this Agreement: Section 2.5 (No Implied Rights); Section 3.2 (Contributions); Section 4 (Ownership); Section 7.2 (Subscriber's Representations and Warranties); Section 7.3 (Warranty Disclaimer); Section 8 (Limitations on Remedies); Section 9 (Indemnification); Section 10 (Confidentiality); Section 11.3 (Effect of Termination or Expiration); and Section 12 (General).
                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.9</strong><span className="space"></span>
                        <strong>Notice.</strong> Any notices required or permitted herein shall be given to the Subscriber at the address specified on the submission form, to MGT at:
                    </div>
                    <div className="terms-head">
                        <strong>MGT of America Consulting, LLC</strong>
                    </div>
                    <div className="terms-head">
                        <strong>4320 W. Kennedy Blvd., Ste 200</strong>
                    </div>
                    <div className="terms-head">
                        <strong>Tampa, FL 33609</strong>
                    </div>
                    <div className="terms-desc">
                        Notice shall be deemed given: (i) upon personal delivery; (ii) if sent by certified or registered mail, postage prepaid, five (5) days after the date of mailing.  A Party may change the address for Notice by specifying in writing such updated address as the Party desires.                    </div>
                    <div className="terms-desc start-margin">
                        <strong>12.10</strong><span className="space"></span>
                        <strong>Remedies.</strong> Except as expressly stated herein, no remedy conferred by any of the provisions of this Agreement is intended to be exclusive of any other remedy, and each remedy is cumulative and in addition to every other remedy available to a Party hereunder or otherwise existing at law, in equity, by statute, or otherwise. The election of any one or more remedies by either Party shall not constitute a waiver of the right to pursue any other available remedies.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions